import React from 'react';
import styled from 'styled-components';
import { Box, Text, Heading } from '@chakra-ui/core';
import GEARS from './gears-data';

import { mediaQueries } from '../../helpers';

const Grid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-top: 24px;

  ${mediaQueries.laptop(`
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 8px;
  `)}

  ${mediaQueries.mobile(`
    grid-template-columns: repeat(1, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 8px;
  `)}
`;

const Gear = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 16px;
  margin-right: 16px;

  ${mediaQueries.laptop(`
    padding: 8px;
    margin-right: 8px;
  `)}

  ${mediaQueries.mobile(`
    padding: 16px 24px;
  `)}
`;

const Gears = props => {
  const { slug } = props;

  return (
    <>
      <Heading fontSize="2xl" marginBottom="24px">
        Équipements
      </Heading>
      <Grid>
        {GEARS.filter(item => !item.isFiltered || item.isFiltered !== slug).map(
          (stuff, index) => (
            <Gear key={index}>
              <Box as={stuff.icon} size="24px" marginRight="16px" />
              <Text>{stuff.name}</Text>
            </Gear>
          )
        )}
      </Grid>
    </>
  );
};

export default Gears;
