import React from 'react';
import styled from 'styled-components';
import { FaArrowRight } from 'react-icons/fa';
import { Box, useTheme, Text, Link, Image } from '@chakra-ui/core';
import Rating from './rating';
import { mediaQueries } from '../../helpers';
import Details from './Details';
import { PaymentMethods } from '../common';

const Wrapper = styled.div`
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${mediaQueries.desktop(`
    width: 100vw;
    flex-direction: row-reverse;
    bottom: -2px;
    position: fixed;
    background-color: white;
    left: 0;
    border-top: 1px solid #edf2f7; 
    padding: 16px;
    box-shadow: 1px 0px 35px -5px rgba(0,0,0,0.44);
    z-index: 5;
  `)}

  ${mediaQueries.mobile(`
    flex-direction: column-reverse;
  `)}
`;

const ButtonWrapper = styled.div`
  margin-bottom: 24px;
  width: 100%;

  ${mediaQueries.desktop(`
    margin-bottom: 0px;
  `)}

  ${mediaQueries.mobile(`
    width: 100%;
    margin-top: 16px;
  `)}
`;

const DetailsWrapper = styled.div`
  padding: 24px;
  border: 1px solid #edf2f7;
  border-radius: 4px;
  overflow: hidden;
  width: 100%;
  margin-top: 24px;

  ${mediaQueries.desktop(`
    display: none;
  `)}
`;

const Button = styled.button`
  background: ${props => props.colors.blue[700]};
  color: ${props => props.colors.gray[200]};
  padding: 12px;
  width: 100%;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background 200ms;

  &:hover {
    background: ${props => props.colors.blue[800]};
  }
`;

const PaymentsWrapper = styled.div`
  margin-top: 24px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;

  ${mediaQueries.desktop(`
    display: none;
  `)}
`;

const AwardWrapper = styled.div`
  margin-top: 24px;
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 4px;
  overflow: hidden;

  ${mediaQueries.desktop(`
    display: none;
  `)}
`;

const Sidebar = props => {
  const { houseID, slug } = props;
  const theme = useTheme();

  const { colors } = theme;

  return (
    <Wrapper colors={colors}>
      <ButtonWrapper>
        <Button
          as={Link}
          target="_blank"
          rel="noopener noreferer"
          href={`https://checkout.lodgify.com/le-coccon-de-sarah/fr/?currency=EUR#/${houseID}`}
          colors={colors}
        >
          <Box as={FaArrowRight} marginRight="8px" marginTop="2px" />
          <Text fontSize="lg">Réserver</Text>
        </Button>
      </ButtonWrapper>

      <Rating />

      <DetailsWrapper>
        <Details />
      </DetailsWrapper>

      {slug === 'coup-coeur' && (
        <AwardWrapper>
          <Image
            width="100%"
            src="https://res.cloudinary.com/dzba82asr/image/upload/v1706627201/lecoccondesarah/coup-de-coeur/pcahu9onk2ddyhktxnjq.jpg"
          />
        </AwardWrapper>
      )}

      <PaymentsWrapper>
        <PaymentMethods />
      </PaymentsWrapper>
    </Wrapper>
  );
};

export default Sidebar;
