import React from 'react';
import styled from 'styled-components';
import { Button, useTheme } from '@chakra-ui/core';
import BackgroundImage from 'gatsby-background-image';
import { AiOutlinePicture } from 'react-icons/ai';
import { mediaQueries } from '../../helpers';

const GalleryWrapper = styled.div`
  max-width: 1440px;
  height: 50vh;
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 16px 32px;
  margin: auto;

  ${mediaQueries.desktop(`
    padding: 16px;
  `)}
`;

const Banner = ({ image, setGalleryOpen }) => {
  const theme = useTheme();

  const backgroundFluidImageStack = [
    `linear-gradient(rgba(0, 0, 0, 0.10), rgba(0, 0, 0, 0.10))`,
    image.childImageSharp.fluid,
  ];

  return (
    <BackgroundImage
      Tag="section"
      fluid={backgroundFluidImageStack}
      backgroundColor={theme.colors.gray[500]}
      alt="Photo de notre appartement"
    >
      <GalleryWrapper>
        <Button
          leftIcon={AiOutlinePicture}
          variantColor="gray"
          onClick={setGalleryOpen}
        >
          Voir les photos
        </Button>
      </GalleryWrapper>
    </BackgroundImage>
  );
};

export default Banner;
