import { IoIosWifi, IoIosWoman, IoIosFilm } from 'react-icons/io';
import { FaBath, FaCarSide, FaBaby, FaMountain } from 'react-icons/fa';
import { MdKitchen, MdLocalLaundryService } from 'react-icons/md';
import { GiPapers, GiFrostfire, GiHeatHaze } from 'react-icons/gi';

const GEARS = [
  {
    name: 'Wifi',
    icon: IoIosWifi,
  },
  {
    name: 'Climatisation',
    icon: GiFrostfire,
    isFiltered: 'coup-coeur',
  },
  {
    name: 'TV cable',
    icon: IoIosFilm,
  },
  {
    name: 'Salle de bain',
    icon: FaBath,
  },
  {
    name: 'Cuisine équipée',
    icon: MdKitchen,
  },
  {
    name: 'Parking',
    icon: FaCarSide,
  },
  {
    name: 'Seche cheuveux',
    icon: IoIosWoman,
  },
  {
    name: 'Linge de maison',
    icon: GiPapers,
  },
  {
    name: 'Terrasse / Balcon',
    icon: FaMountain,
  },
  {
    name: 'Équipement bébé',
    icon: FaBaby,
    isFiltered: 'coup-coeur',
  },
  {
    name: 'Lave-linge',
    icon: MdLocalLaundryService,
  },
  {
    name: 'Chauffage',
    icon: GiHeatHaze,
  },
];

export default GEARS;
