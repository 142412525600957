import React from 'react';
import styled from 'styled-components';
import { ActivityCard } from '../guide';
import { mediaQueries } from '../../helpers';

const Grid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 24px;
  grid-row-gap: 36px;
  margin-top: 36px;

  ${mediaQueries.laptop(`
    grid-template-columns: repeat(1, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 24px;
  `)}
`;

const ActivityGrid = ({ activities }) => {
  return (
    <Grid>
      {activities.map(item => {
        const activity = item.node.frontmatter;
        return (
          <ActivityCard
            key={`${activity.title}-${activity.id}`}
            activity={activity}
            isSmall
          />
        );
      })}
    </Grid>
  );
};

export default ActivityGrid;
