import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import 'react-bnb-gallery/dist/style.css';
import { Box, Text, Stack, Heading, Divider } from '@chakra-ui/core';
import {
  Banner,
  Sidebar,
  Gears,
  ActivityGrid,
  Details,
} from '../components/appartements';
import Gallery from '../components/appartements/gallery';
import Layout from '../components/skeleton/layout';
import SEO from '../components/skeleton/seo';
import { mediaQueries } from '../helpers';
import APPARTS from '../static-data/appartements';

const HeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${mediaQueries.mobile(`
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  `)}
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  padding: 16px;
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  ${mediaQueries.mobile(`
    padding: 8px;
  `)}
`;

const MainContent = styled.div`
  width: 65%;
  min-height: 100%;

  ${mediaQueries.desktop(`
    width: 100%
  `)}
`;

const DetailsContainer = styled.div`
  display: none;

  ${mediaQueries.desktop(`
    display: block;
  `)}
`;

const MainDetails = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #edf2f7;
  margin-bottom: 24px;
  margin-top: 16px;

  ${mediaQueries.mobile(`
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-bottom: 8px;
  `)}
`;

const DetailsBox = styled.div`
  padding: 16px 24px;

  ${mediaQueries.tablet(`
    padding: 8px;
  `)}
`;

const PriceWrapper = styled.div`
  ${mediaQueries.mobile(`
    margin-top: 16px;
  `)}
`;

export default ({ data, pageContext }) => {
  const [isGalleryOpened, setOpened] = React.useState(false);
  const { placeholderImage } = data;
  const {
    name,
    people,
    beds,
    localisation,
    appartID,
    basePrice,
    slug,
  } = data.markdownRemark.frontmatter;
  const activities = data.allMarkdownRemark.edges;
  const description = data.markdownRemark.html;

  const appartDetails = APPARTS.filter(appart => appart.slug === slug);
  const { rating, reviewCount, imageUrl } = appartDetails[0];

  return (
    <Layout
      headerChildren={
        <Banner
          image={placeholderImage}
          setGalleryOpen={() => setOpened(true)}
        />
      }
    >
      <SEO
        title={`L'appartement ${name}`}
        description={`Le cocoon de Sarah vous propose l'appartement ${name} situé à ${localisation} pour vos sejours dans la vallée du Mont-blanc`}
        ld={`{
          "@context": "http://schema.org",
          "@type": "Hotel",
          "name": "${name}",
          "image": "${imageUrl}",
          "priceRange": "À partir de ${basePrice} par nuit pour les dates à venir (nous ajustons nos tarifs)",
          "description": "Le cocoon de Sarah vous propose l'appartement ${name} situé à ${localisation} pour vos sejours dans la vallée du Mont-blanc",
          "aggregateRating": {
            "@type": "AggregateRating",
            "ratingValue": ${rating},
            "bestRating": 5,
            "reviewCount": ${reviewCount}
          },
          "address": {
            "postalCode": "74400",
            "@type": "PostalAddress",
            "addressCountry": "France",
            "streetAddress": "${localisation}, France",
            "addressRegion": "Rhône-Alpes"
          },
          "url": "lecocoondesarah.com/${slug}"
        }`}
      />

      <Gallery
        isOpened={isGalleryOpened}
        onClose={() => setOpened(false)}
        appartID={appartID}
      />

      <Container>
        <MainContent>
          <HeaderWrapper>
            <Stack>
              <Heading fontSize="4xl" color="gray.600">
                {name}
              </Heading>
              <Text fontSize="1xl" as="em" color="gray.500">
                {localisation}
              </Text>
            </Stack>
            <PriceWrapper>
              <Text fontSize="1xl" as="em">
                A partir de{' '}
                <Text fontSize="4xl" as="b" margin="0 8px">
                  {basePrice}€
                </Text>
                / nuit
              </Text>
            </PriceWrapper>
          </HeaderWrapper>

          <MainDetails>
            <DetailsBox>{people} personnes</DetailsBox>
            <DetailsBox>1 chambre</DetailsBox>
            <DetailsBox>{beds} lit double</DetailsBox>
            <DetailsBox>1 SDB</DetailsBox>
          </MainDetails>

          <DetailsContainer>
            <Details />
            <Divider />
          </DetailsContainer>

          <Box marginTop="32px">
            <div dangerouslySetInnerHTML={{ __html: description }}></div>
          </Box>

          <Box className="main-stuff" marginTop="32px">
            <Gears slug={slug} />
          </Box>

          <Box className="main-activities" marginTop="32px">
            <Heading fontSize="2xl">A proximité</Heading>
            <ActivityGrid activities={activities} />
          </Box>
        </MainContent>

        <Sidebar houseID={appartID} slug={slug} />
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query($slug: String, $picture: String) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        name
        people
        beds
        babyBed
        localisation
        kind
        appartID
        basePrice
        slug
      }
    }
    allMarkdownRemark(
      filter: { frontmatter: { showOnAppart: { eq: $slug } } }
    ) {
      edges {
        node {
          frontmatter {
            title
            city
            icon
            kind
            pictureURL
            cost
            shortDescription
            fullDescription
            season
            link
          }
        }
      }
    }
    placeholderImage: file(relativePath: { eq: $picture }) {
      childImageSharp {
        fluid(maxWidth: 2200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
