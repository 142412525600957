const APPARTS = [
  {
    appartID: 285242,
    imageUrl:
      'https://res.cloudinary.com/dzba82asr/image/upload/v1639350329/lecoccondesarah/profils/le-cocoon_ys3eoo.jpg',
    imageAlt: 'Le cocoon de Sarah, appartement 4 personnes à Chamonix',
    beds: 1,
    baths: 1,
    title: 'Le Cocoon',
    formattedPrice: '49€',
    reviewCount: 18,
    rating: 4.5,
    locality: 'Chamonix mont-blanc',
    slug: 'le-cocoon',
    people: 2,
    intro: `Venez découvrir l'appartement Le Cocoon à Chamonix mont-blanc.
    Appartement luxueux 2 étoiles de 23 m² entièrement rénové avec gout et modernité, 
    pouvant accueillir 2 personnes.`,
    description: `
    Idéalement situé au centre du village (très calme) des Bossons, 
    dans la commune de Chamonix Mont-Blanc et proche de toutes commodités.`,
  },
  {
    appartID: 285243,
    imageUrl:
      'https://res.cloudinary.com/dzba82asr/image/upload/v1639396721/lecoccondesarah/photos%20cosy%203/salon_1.jpg',
    imageAlt: 'Le Cosy, appartement 2 personnes à Chamonix',
    beds: 1,
    baths: 1,
    title: 'Le Cosy',
    formattedPrice: '59€',
    reviewCount: 48,
    rating: 4.5,
    locality: 'Chamonix mont-blanc',
    slug: 'le-cosy',
    people: 2,
    intro: `Venez découvrir l'appartement Le Cosy à Chamonix mont-blanc.
    Appartement luxueux 3 étoiles de 25 m² entièrement rénové avec gout et
    modernité, pouvant accueillir 2 personnes.`,
    description: `Idéalement situé au centre du village des Bossons, dans la commune
    de Chamonix Mont-Blanc et proche de toutes commodités.`,
  },
  {
    appartID: 354786,
    imageUrl:
      'https://res.cloudinary.com/dzba82asr/image/upload/v1639350316/lecoccondesarah/profils/coup-coeur_tj4mqn.jpg',
    imageAlt: 'Le Coup de Coeur, appartement 2 personnes aux Houches',
    beds: 1,
    baths: 1,
    title: 'Le Coup de Coeur',
    formattedPrice: '79€',
    reviewCount: 22,
    rating: 4.5,
    locality: 'Les houches',
    slug: 'coup-coeur',
    people: 2,
    intro: `Venez découvrir l'appartement Le Coup de Coeur aux Houches.
    Appartement luxueux 4 étoiles de 30 m² entièrement rénové avec gout et
    modernité, pouvant accueillir 2 personnes.`,
    description: `Idéalement situé à 100 mètres des pistes de ski et 10min en voiture de Chamonix. Vous vous sentirez comme chez vous !`,
  },
];

export default APPARTS;
