import React from 'react';
import styled from 'styled-components';
import { GiHouse, GiVacuumCleaner, GiRibbonMedal } from 'react-icons/gi';
import { Box, Text, List, ListItem, Heading } from '@chakra-ui/core';
import { mediaQueries } from '../../helpers';

const ListWrapper = styled.div`
  ${mediaQueries.desktop(`
    margin-bottom: 24px;
  `)}
`;

const Details = () => {
  return (
    <ListWrapper>
      <List spacing={3}>
        <ListItem
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          justifyContent="center"
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            marginBottom="8px"
          >
            <Box as={GiHouse} marginRight="8px" />
            <Heading size="sms">Logement entier</Heading>
          </Box>
          <Text fontSize="sm" color="gray.500">
            Vous aurez le logement (appartement) rien que pour vous.
          </Text>
        </ListItem>
        <ListItem
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          justifyContent="center"
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            marginBottom="8px"
          >
            <Box as={GiVacuumCleaner} marginRight="8px" />
            <Heading size="sm">Parfaitement propre</Heading>
          </Box>
          <Text fontSize="sm" color="gray.500">
            Tous les voyageurs ont indiqué que ce logement était d'une propreté
            irréprochable.
          </Text>
        </ListItem>
        <ListItem
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          justifyContent="center"
          marginBottom="0"
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            marginBottom="8px"
          >
            <Box as={GiRibbonMedal} marginRight="8px" />
            <Heading size="sm">Superhost</Heading>
          </Box>
          <Text fontSize="sm" color="gray.500">
            Les Superhosts sont des hôtes AirBnb expérimentés qui bénéficient de
            très bonnes évaluations et qui s'engagent à offrir d'excellents
            séjours aux voyageurs.
          </Text>
        </ListItem>
      </List>
    </ListWrapper>
  );
};

export default Details;
