import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import ReactBnbGallery from 'react-bnb-gallery';
import 'react-bnb-gallery/dist/style.css';

export default ({ isOpened, onClose, appartID }) => {
  const {
    site: {
      siteMetadata: { galleryData },
    },
  } = useStaticQuery(graphql`
    query GalleryQuery {
      site {
        siteMetadata {
          galleryData {
            id
            photos {
              photo
              caption
              subcaption
              thumbnail
            }
          }
        }
      }
    }
  `);
  const photos = galleryData.filter(collections => collections.id === appartID);

  return (
    <ReactBnbGallery
      show={isOpened}
      photos={photos[0].photos}
      onClose={onClose}
      opacity={0.9}
    />
  );
};
