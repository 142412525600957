import React from 'react';
import styled from 'styled-components';
import { Flex, Heading, Icon, useTheme } from '@chakra-ui/core';
import { mediaQueries } from '../../helpers';

const RatingWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;

  ${mediaQueries.desktop(`
    justify-content: center;
  `)}
`;

const Rating = () => {
  const theme = useTheme();

  return (
    <RatingWrapper>
      <Heading fontSize="l">Note:</Heading>
      <Flex
        justify="space-between"
        align="center"
        maxW="250px"
        marginLeft="16px"
      >
        <Icon name="star" color={theme.colors.blue[700]} marginRight="8px" />
        <Icon name="star" color={theme.colors.blue[700]} marginRight="8px" />
        <Icon name="star" color={theme.colors.blue[700]} marginRight="8px" />
        <Icon name="star" color={theme.colors.blue[700]} marginRight="8px" />
        <Icon name="star" color={theme.colors.gray[400]} />
      </Flex>
    </RatingWrapper>
  );
};

export default Rating;
